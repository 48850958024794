import { WebGLRenderer } from 'three/src/renderers/WebGLRenderer';
import { PerspectiveCamera } from 'three/src/cameras/PerspectiveCamera';
import { Vector3 } from 'three/src/math/Vector3';
import { Mesh } from 'three/src/objects/Mesh';
import { Scene } from 'three/src/scenes/scene';
import { _Math } from 'three/src/math/Math';
import { MeshBasicMaterial } from 'three/src/materials/MeshBasicMaterial';
import { SphereBufferGeometry } from 'three/src/geometries/SphereGeometry';
import { VideoTexture } from 'three/src/textures/VideoTexture';
import { Texture } from 'three/src/textures/Texture';
import { Euler } from 'three/src/math/Euler';
import { Quaternion } from 'three/src/math/Quaternion';
import { REVISION, LinearFilter, NearestFilter, RGBFormat } from 'three/src/constants';

// Treeshake only the things we need 🌲
const THREE = {
  'WebGLRenderer': WebGLRenderer,
  'PerspectiveCamera': PerspectiveCamera,
  'Vector3': Vector3,
  'Mesh': Mesh,
  'MeshBasicMaterial': MeshBasicMaterial,
  'VideoTexture': VideoTexture,
  'Texture': Texture,
  'SphereBufferGeometry': SphereBufferGeometry,
  'Math': {
    'radToDeg': _Math.radToDeg,
    'degToRad': _Math.degToRad
  },
  'Euler': Euler,
  'Quaternion': Quaternion,
  'Scene': Scene,
  'LinearFilter': LinearFilter,
  'NearestFilter': NearestFilter,
  'RGBFormat': RGBFormat,
  'Version': REVISION
}

// Attach the namespace to the window
window.THREE = THREE;

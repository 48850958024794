let nativeArrayFrom = window.Array.from;
const testArray = [1];

// Mootools has a shitty implementation where they pass the array through if
// it is an array already. Our primary use case is to copy arrays, so pretend
// we don’t have it at all if the “native” one doesn’t copy the array.
if (typeof nativeArrayFrom === 'function' && nativeArrayFrom(testArray) === testArray) {
    nativeArrayFrom = false;
}

const arrayFrom = nativeArrayFrom || function(arraylike) {
    return [].slice.call(arraylike, 0);
};

export default arrayFrom;
